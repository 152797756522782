.chatbot-container {
  width: 100%;
  height: 100%;
  background-color: #f7f8f9;
}

.chatbot-content {
  max-width: 780px;
  height: 100%;
  margin: 0 auto;
  border-right: 1px solid #d1d5d7;
  border-left: 1px solid #d1d5d7;
  background: white;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chatbot-messages {
  padding: 10px 20px 64px;
  flex: 1;
  overflow-y: auto;
}

.chatbot-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.chatbot-buttons button:last-child {
  margin-right: 0 !important;
}

.chatbot-buttons.car {
  height: 420px !important;
  place-items: flex-end;
  width: 700px;
  margin-top: -40px;
}

.chatbot-buttons.car button {
  display: inline-block;
  position: relative;
  border: 2px solid transparent;
  background: transparent;
}

.chatbot-buttons.car button:hover {
  border: 2px solid #00008f;
}

.chatbot-buttons.car button.active {
  border: 2px solid #00008f;
}

.chatbot-buttons.car button img {
  max-height: 64px !important;
  max-width: 64px !important;
}

.chatbot-buttons.car button:nth-child(1) {
  left: 150px;
}

.chatbot-buttons.car button:nth-child(2) {
  left: 150px;
  top: 90px;
}

.chatbot-buttons.car button:nth-child(3) {
  top: 105px;
  left: -85px;
}

.chatbot-buttons.car button:nth-child(4) {
  top: 45px;
  left: -116px;
}

.chatbot-buttons.car button:nth-child(5) {
  top: 90px;
  left: -310px;
}

.chatbot-buttons.car button:nth-child(6) {
  top: 105px;
  left: -235px;
}

.chatbot-buttons.car button:nth-child(7) {
  top: 180px;
  left: -460px;
}

.chatbot-buttons.car button:nth-child(8) {
  top: 180px;
  left: -485px;
}

.chatbot-buttons.car button:nth-child(9) {
  top: -95px;
  left: 180px;
}

.chatbot-buttons.car button:nth-child(10) {
  top: -10px;
  left: 149px;
}

.chatbot-buttons.car button:nth-child(11) {
  left: 125px;
  top: -10px;
}

.chatbot-buttons.car button:nth-child(12) {
  left: -130px;
  top: 60px;
}

.chatbot-buttons.car button:nth-child(13) {
  left: -158px;
  top: 60px;
}

.chatbot-buttons.car button:nth-child(14) {
  top: 45px;
  left: -190px;
}

.chatbot-buttons.car button:nth-child(14) img {
  width: 64px;
  height: 138px !important;
  max-height: none !important;
}

.chatbot-buttons.car button:nth-child(15) {
  top: 60px;
  left: -220px;
}

.chatbot-buttons.car button:nth-child(16) {
  top: 60px;
  left: -248px;
}

.chatbot-buttons.car button:nth-child(17) {
  top: 125px;
  left: -302px;
}

.chatbot-buttons.car button:nth-child(18) {
  top: -10px;
  left: -110px;
}

.chatbot-buttons.car button:nth-child(19) {
  top: -10px;
  left: -140px;
}

.chatbot-buttons.car button:nth-child(20) {
  top: -11px;
  left: -170px;
}

.chatbot-buttons.car button:nth-child(21) {
  top: 11px;
  left: -450px;
}

.chatbot-buttons.car button:nth-child(22) {
  top: 30px;
  left: -425px;
}

.answer .header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.answer .avatar {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.avatar.bot {
  background-color: #00008f;
  color: white;
  margin-right: 10px;
}

.avatar.bot img {
  width: 36px;
  height: 36px;
}

.avatar.user {
  background-color: #ec4d33;
  color: white;
  margin-left: 10px;
}

.bubble {
  line-height: 20px;
  max-width: 75%;
  border: 1px solid #eaedef;
  word-break: break-word;
  position: relative;
  font-size: 14px;
  background-color: #eaedef;
  -webkit-border-radius: 0 20px 20px 20px;
  -moz-border-radius: 0 20px 20px 20px;
  border-radius: 0 20px 20px 20px;
  color: #333;
  padding: 15px;
  margin-bottom: 10px;
}

.answer .header.user {
  justify-content: flex-end;
}

.bubble.user {
  background: #ec4d33;
  color: white;
  border: 1px solid #ec4d33;
  line-height: 20px;
  max-width: 75%;
  border: 1px solid #00008f;
  word-break: break-word;
  position: relative;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #00008f;
  -webkit-border-radius: 20px 0 20px 20px;
  -moz-border-radius: 20px 0 20px 20px;
  border-radius: 20px 0 20px 20px;
  color: #fff;
  padding: 15px;
}

button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  /* opacity: 0; */
  max-width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #00008f;
  border-radius: 3px;
  margin: 0 10px 10px 0;
  padding: 10px;
  color: #00008f;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

button:hover,
button:focus {
  cursor: pointer;
  border: 2px solid #00008f;
  background-color: #00008f;
  color: white;
  outline: none;
}

.answer.user {
  align-items: flex-end;
}

.answer {
  display: flex;
  flex-direction: column;
}

.chatbot-input-container {
  min-height: 64px;
  position: sticky;
  align-self: flex-end;
  bottom: 0;
  background: #f0f1f5;
  width: 100%;
  display: flex;
}

.chatbot-input-container.archived {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d1d5d7;
}

.chatbot-input-container textarea {
  width: 100%;
  border-radius: 0;
  resize: none;
  overflow: hidden;
}

.chatbot-input-container {
  border-top: 1px solid #d1d5d7;
}

.chatbot-input-container:focus-within {
  border-top: 1px solid #00008f;
}

.chatbot-input-container textarea:focus {
  outline: none;
}

.chatbot-input-container textarea:disabled {
  background-color: #f0f1f5;
}

.chatbot-send-btn {
  margin: 0;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #00008f !important;
}

#upload-photo {
  display: none;
}

.file-upload {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-flow: row nowrap;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  /* opacity: 0; */
  max-width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #00008f;
  border-radius: 3px;
  margin: 0 10px 10px 0;
  padding: 10px;
  color: #00008f;
  font-size: 14px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.file-upload:hover {
  cursor: pointer;
  border: 2px solid #00008f;
  background-color: #00008f;
  color: white;
  outline: none;
}

.file-upload-container {
  flex-direction: column;
  align-items: flex-end;
}

button:disabled {
  background-color: #f0f1f5;
  border: 2px solid #d1d5d7;
  color: #d1d5d7;
}

.chatbot-buttons.datepicker {
  flex-direction: column;
  align-items: flex-end;
}
