html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}

textarea {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,sans-serif;
  padding: 15px;
  font-size: 14px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.top-bar {
  position: fixed;
  width: 100%;
}

.logo {
  border-bottom: 1px solid #d1d5d7;
}

.logo img {
  height: 64px;
}

.navigation-bar {
  height: 60px;
  background: #f7f8f9;
  display: flex;
  border-bottom: 1px solid #d1d5d7;
}

.nav-item {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d1d5d7;
}

.nav-item:last-child {
  border: none;
}

.nav-item:hover {
  cursor: pointer;
}

.nav-item i,
.nav-item svg {
   color: #999999;
}

.nav-item:hover i,
.nav-item:hover svg,
.nav-item.active i,
.nav-item.active svg {
   color: #00008f;
}

html, body, #root, iframe, .container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
}

.container {
  padding-top: 66px;
}

.logo {
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

select.pisano-select {
  background-color: #fff;
  cursor: pointer;
  border: 2px solid #00008f;
  border-radius: 3px;
  padding: 10px;
  outline: none;
  appearance: none;
  font-size: 14px;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  -webkit-transition: .3s;
  -o-transition: .3s;
  -moz-transition: .3s;
  transition: .3s;
}

/* select.pisano-select::after { */
/*   content: ""; */
/*   width: 0.8em; */
/*   height: 0.5em; */
/*   background-color: var(--select-arrow); */
/*   clip-path: polygon(100% 0%, 0 0%, 50% 100%); */
/*   justify-self: end; */
/* } */

select.pisano-select,
select.pisano-select:after {
  grid-area: select;
}

.float-right {
  float: right;
}

.car-parts-next { margin-top: 20px; }

.office-hours {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-left: 1px solid #d1d5d7;
  height: 100%;
  box-sizing: border-box;
  background: #f7f8f9;
}

.office-hours-message {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  max-width: 780px;
  border-right: 1px solid #d1d5d7;
  border-left: 1px solid #d1d5d7;
  padding: 0 20px;
  line-height: 1.4em;
}
